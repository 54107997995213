@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-snap-type: y mandatory;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dark .button:not(:hover:focus-within) {
  /* padding: 16px 42px;
  border-radius: 3px; */
  /* box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.5); */
  /* line-height: 1.25; */
  /* background: #FC6E51; */
  /* text-decoration: none; */
  /* color: white; */
  /* font-size: 16px; */
  /* letter-spacing: .08em; */
  /* text-transform: uppercase; */
  position: relative;
  transition: background-color 0.6s ease;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    width: 0px;
    height: 0px;
    top: 50%;
    left: 50%;
    top: var(--mouse-y);
    left: var(--mouse-x);
    /* top: 6;
    left: 10; */
    transform-style: flat;
    transform: translate3d(-50%, -50%, 0);
    /* background: rgba(255, 255, 255, 0); */
    border-radius: 100%;
    transition: background 0.2s ease, width 0.3s ease, height 0.3s ease;
  }

  /* &:focus, */
  /* &:hover { */
  /* background: darken(#FC6E51, 87%); */
  /* background: rgba(255, 255, 255, 0.1); */
  /* } */

  &:active {
    &:after {
      background: rgba(255, 255, 255, 0.1);
      width: 200vw;
      height: 200vw;
    }
  }
}

.button:not(:hover:focus-within) {
  /* padding: 16px 42px;
  border-radius: 3px; */
  /* box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.5); */
  /* line-height: 1.25; */
  /* background: #FC6E51; */
  /* text-decoration: none; */
  /* color: white; */
  /* font-size: 16px; */
  /* letter-spacing: .08em; */
  /* text-transform: uppercase; */
  position: relative;
  transition: background-color 0.6s ease;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    width: 0px;
    height: 0px;
    top: 50%;
    left: 50%;
    top: var(--mouse-y);
    left: var(--mouse-x);
    /* top: 6;
    left: 10; */
    transform-style: flat;
    transform: translate3d(-50%, -50%, 0);
    /* background: rgba(255, 255, 255, 0); */
    border-radius: 100%;
    transition: background 0.2s ease, width 0.3s ease, height 0.3s ease;
  }

  /* &:focus, */
  /* &:hover { */
  /* background: darken(#FC6E51, 87%); */
  /* background: rgba(255, 255, 255, 0.1); */
  /* } */

  &:active {
    &:after {
      background: rgba(0, 0, 0, 0.1);
      width: 200vw;
      height: 200vw;
    }
  }
}

input[type='checkbox'].switch_1 {
  font-size: 24px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  min-width: 32px;
  width: 32px;
  height: 18px;
  background: #ddd;
  border-radius: 3em;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

input[type='checkbox'].switch_1:checked {
  background: #377aff;
}

input[type='checkbox'].switch_1:after {
  position: absolute;
  content: '';
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #fff;
  -webkit-box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  left: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

input[type='checkbox'].switch_1:checked:after {
  left: calc(100% - 22px);
}

input[type='checkbox'].switch_2 {
  font-size: 28px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  min-width: 32px;
  width: 36px;
  height: 22px;
  background: #ddd;
  border-radius: 3em;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

input[type='checkbox'].switch_2:checked {
  background: #099594;
}

input[type='checkbox'].switch_2:after {
  position: absolute;
  content: '';
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #fff;
  -webkit-box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  left: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

input[type='checkbox'].switch_2:checked:after {
  left: calc(100% - 22px);
}
